<template>
    <div class="dict">
        <div class="title">历史消息</div>
        <ul>
            <li @click="goDetail(item)" v-for="item in newsList" :key="item.id">
                <div :class="item.remindMessageType.value == 1?'newsIcon bg1':item.remindMessageType.value == 2?'newsIcon bg2':item.remindMessageType.value == 3?'newsIcon bg4':item.remindMessageType.value == 4?'newsIcon bg3':item.remindMessageType.value == 7?'newsIcon bg5':'newsIcon'">
                    <img v-if="item.remindMessageType.value == 1" src="@/assets/img/new-type-1.png" alt="">
                    <img v-if="item.remindMessageType.value == 2" src="@/assets/img/new-type-2.png" alt="">
                    <img v-if="item.remindMessageType.value == 3" src="@/assets/img/new-type-4.png" alt="">
                    <img v-if="item.remindMessageType.value == 4" src="@/assets/img/new-type-3.png" alt="">
                    <img v-if="item.remindMessageType.value == 7" src="@/assets/img/new-type-5.png" alt="">
                    <span v-if="item.isRead.value == 0" class="isRead"></span>
                </div>
                <div class="newsitem">
                    <div class="newsTitle">
                        {{item.message}}
                    </div>
                    <!-- <div class="newsHint" v-if="item == 3">任务名称任务名称任务名称任务名称任务名称任务名称</div> -->
                    <div class="newsTime">{{item.createTime}}</div>
                </div>
            </li>
        </ul>
        <div class="noData" v-if="newsList.length == 0">暂无数据</div>
        <div v-if=" ipagination.total > 0" class="l-pages">
        <div class="total-num">
            <!-- 显示第{{ showNum }}条记录，共{{ ipagination.total }}条记录 -->
        </div>
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="current"
            @size-change="handleSizeChange"
            :page-size="ipagination.size"
            :page-sizes="ipagination.pageSizes"
            layout="total,sizes,prev,pager,next,jumper"
            :total="ipagination.total"
        ></el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            current:1,
            ipagination:{
                num: 1,
                size: 10,
                pageSizes: [10, 20, 50, 100, 500],
                total: 0,
            },
            newsList:[],
            role:0,
        }
    },
    computed: {
        showNum() {
            let start = (this.current - 1) * this.ipagination.size + 1;
            let end =
                this.current * this.ipagination.size > this.ipagination.total
                ? this.ipagination.total
                : this.current * this.ipagination.size;
            return this.ipagination.total === 0
                ? this.ipagination.total
                : `${ start }-${ end }`;
        },
    },
    created(){
        this.init()
        if(this.$store.state.userInfo.authList.length == 0){
            // 园校
            this.role = 3
        }else if(this.$store.state.userInfo.authList[0] == 'org:z'){
            // 总部
            this.role = 1
        }else if(this.$store.state.userInfo.authList[0] == 'org:f'){
            // 分公司
            this.role = 2
        }
    },
    methods:{
        init(){
            this.$api.getHistoryNews({
                current:this.current,
                size:this.ipagination.size
            }).then(res=>{
                if(res.data.code == '0'){
                    this.newsList = res.data.data.list.records
                    this.ipagination.total = res.data.data.list.total
                }else{
                    this.newsList = []
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleSizeChange(val){
            this.current = 1
            this.ipagination.size = val;
            this.init()
        },
        handleCurrentChange(val){
            this.current = val
            this.init()
        },
        // 修改消息状态
        goDetail(row){
            console.log(row.isRead.value)
            if(row.isRead.value == 0){
                this.$api.setHistoryNewsType({
                    id:row.id,
                    isRead:1,
                }).then(res=>{
                    if(res.data.code == '0'){
                        
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }
            
            if(row.taskType.value == 1){
                // 目标
                if(row.remindMessageType.value == 1){
                    // 跳转目标列表
                    // 园校目标列表
                     let pathUrl = ''
                    if(this.role == 1){
                        pathUrl = '/targetListHeadquarters'
                    }else if(this.role == 2){
                        pathUrl = '/targetListCompany'
                    }else if(this.role == 3){
                        pathUrl = '/targetListParkschool'
                    }
                    this.$router.push({
                        path:pathUrl
                    })
                }else if(row.remindMessageType.value == 2 || row.remindMessageType.value == 3){
                    // 跳转目标详情
                    this.$router.push({
                        path:'/targetDetail',
                        query:{
                            lookId: row.taskDetailId,
                            type:1,
                        }
                    })
                }
            }else if(row.taskType.value == 2){
                // 运营
                if(row.remindMessageType.value == 1){
                    // 跳转任务列表
                    let pathUrl = ''
                    if(this.role == 1){
                        pathUrl = '/upcomingTasksHead'
                    }else if(this.role == 2){
                        pathUrl = '/upcomingTasksBranchOffice'
                    }else if(this.role == 3){
                        pathUrl = '/upcomingTasksPrincipal'
                    }
                    this.$router.push({
                        path:pathUrl
                    })
                }else if(row.remindMessageType.value == 2 || row.remindMessageType.value == 3 || row.remindMessageType.value == 6){
                    // 跳转待办任务详情
                    this.$router.push({
                        path:'/upcomingTasksDetail',
                        query:{
                            id:row.taskDetailId
                        }
                    })
                }
            }else if(row.taskType.value == 3){
                // 督导
                let pathUrl = ''
                let jumpType = ''
                if(this.role == 1){
                    pathUrl = '/agencyTask'
                    jumpType = 'headquarters'
                }else if(this.role == 2){
                    pathUrl = '/agencyTaskCompany'
                    jumpType = 'company'
                }else if(this.role == 3){
                    pathUrl = '/agencyTaskSchool'
                    jumpType = 'school'
                }
                // if(row.remindMessageType.value == 1){
                //     // 跳转任务列表
                //     // this.$router.push({
                //     //     path:'/superviseTask'
                //     // })
                //     this.$router.push({
                //         path:pathUrl
                //     })
                // }else 
                if(row.remindMessageType.value == 1 || row.remindMessageType.value == 2 || row.remindMessageType.value == 3){
                    // 跳转待办任务详情
                    // this.$router.push({
                    //     path:'/agencyTaskScoreDetail',
                    //     query:{
                    //         id:row.taskDetailId,
                    //         jumpType:jumpType
                    //     }
                    // })
                    
                    this.$api.judgeStatus(row.taskDetailId).then(res=>{
                        if(res.data.code == 0){
                        // console.log(res.data.data,'p[p[p')
                        if(res.data.data == 1){
                            // data == 1 待办
                            this.$router.push({
                                path:'/agencyTaskScoreDetail',
                                query:{
                                    id:row.taskDetailId,
                                    jumpType:jumpType
                                }
                            })
                        }else if(res.data.data == 0){
                            // data == 0 查看
                            this.$router.push({
                                path:'/agencyTaskShareViewDetail',
                                query:{
                                    id:row.taskDetailId,
                                    jumpType:jumpType
                                }
                            })
                        }
                        }
                    })
                }else if(row.remindMessageType.value == 4){
                    // 跳转分享页面
                    this.$router.push({
                        path:'/lightspotDetail',
                        query:{
                            id:row.taskDetailId,
                            jumpType:jumpType
                        }
                    })
                }else if(row.remindMessageType.value == 5){
                    // 跳转分享页面
                    this.$router.push({
                        path:'/agencyTaskShareViewDetail',
                        query:{
                            id:row.taskDetailId,
                            jumpType:jumpType
                        }
                    })
                }
            }else if(row.taskType.value == 4){
                // 疾病防控跳转
                if(row.remindMessageType.value == 7 || row.remindMessageType.value == 8){
                    this.$router.push({
                        path:'/diseaseReport',
                        query:{
                            lookId: row.taskDetailId,
                            lookSubStatus: "2",
                        }
                    })
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.dict{
    padding: 34px 30px 30px 30px;
    .title{
        color: #3D3E4F;
        font-size: 22px;
        font-weight: bold;
    }
    .bg1{
        background-color: #EAF1FC;
    }
    .bg2{
        background-color: #FFE8EA;
    }
    .bg3{
        background-color: #F8EEDF;
    }
    .bg4{
        background-color: #DFF7F3;
    }
    .bg5{
        background-color: #FCF0EA;
    }
    .newsIcon{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .isRead{
            position: absolute;
            top: 0px;
            right: 0px;
            width: 10px;
            height: 10px;
            background-color: #C6000B;
            border-radius: 50%;
        }
    }
    .newsTitle{
        color: #3B3C4D;
        font-size: 14px;
        span{
            margin-top: 6px;
        }
    }
    .newsTime{
        font-size: 12px;
        color: #A8A6B9;
        padding-top: 6px;
    }
    ul {
        margin-top: 20px;
        li{
            cursor: pointer;
            display: flex;
            border-bottom: 1px solid #E6EBF3;
            padding: 12px 0;
            .newsitem{
                margin-left: 12px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .newsHint{
                color: #C6000B;
                font-size: 12px;
                padding-top: 6px;
            }
        }
    }
    
    .l-pages {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        .total-num {
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            color: #7c7c7c;
        }
        .el-pagination {
            padding: 0;
            .el-pagination__sizes {
                margin: 0 0 0 8px;
            }
        }
        .el-pagination .el-select .el-input {
            .el-input__icon {
                right: 20px;
            }
            input {
                width: 100px;
                height: 32px;
                border: 1px solid #e8ecef;
                opacity: 1;
                border-radius: 4px;
                padding: 3px 10px !important;
            }
        }
        .btn-prev,
        .btn-next,
        .el-pager li {
            width: 32px;
            height: 32px;
            border-radius: 6px;
            border: 1px solid #e8ecef !important;
        }
        .btn-next {
            margin-left: 8px;
        }
        .el-pager li {
            line-height: 30px;
            border-radius: 6px;
            font-size: 14px;
            color: #6a6d77;
            margin-left: 8px;
            &:hover {
                background-color: #004ea2 !important;
                border: 1px solid #004ea2 !important;
                color: #ffffff;
            }
        }
        .el-pager li.active {
            background-color: #004ea2 !important;
            border: 1px solid #004ea2 !important;
            color: #ffffff;
        }
    }
    .noData{
        line-height: 80px;
    }
}
</style>